.line__top {
  width: 100%;
  height: auto;
  padding: 14px 0px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  @media (min-width: $lg) {
    //background-color: transparent;
  }
  .wrapper--topbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  .back__to__home {
    display: block;
    width: 30px;
    height: 30px;
    padding: 6px;
    @media (min-width: $lg) {
      width: 50px;
      height: 50px;
    }
    svg {
      width: 100%;
      height: 100%;
      fill: $orange;
      transition: fill .3s ease-in-out;
    }
    &:hover {
      svg {
        fill: $gray;
      }
    }
  }

  // Navigation Desktop
  .main__navigation--desktop {
    display: none;
    @extend .list-reset;
    @media (min-width: $lg) {
      display: flex;
      width: 60%;
      max-width: 600px;
      height: auto;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .link__round {
    display: none;
    @media (min-width: $lg) {
      display: block;
    }
  }

  .burger {
    display: block;
    width: 30px;
    height: 21px;
    cursor: pointer;
    @media (min-width: $lg) {
      display: none;
    }
    .burger__line {
      width: 100%;
      height: 4px;
      border-radius: 1px;
      background-color: $orange;
      margin-bottom: 4px;
      float: right;
      transition: $slow;
      &:first-child {
       transform: scaleX(.6);
      }
      &:nth-child(2) {
        transform: scaleX(.8);
      }
    }

    &:hover, &:focus, &:active {
      .burger__line:first-child {
      transform: scaleX(1);
     }
     .burger__line:nth-child(2) {
       transform: scaleX(.3);
     }
  }
  }
  .burger--cross {
    .burger__line {
      &:first-child {
        transform: translateY(6px) rotate(45deg) !important;
      }
      &:nth-child(2) {
        transform: scaleX(0);
        opacity: 0;
      }
      &:last-child {
        transform: translateY(-10px) rotate(-45deg) !important;
      }
    }
  }
}


// Social Bar Left
.social__bar__left {
  display: none;
  @media (min-width: $lg){
    display: block;
    width: 120px;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9998;
  }
  .social__vertical {
    width: 100%;
    height: 160px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    bottom: 5%;
    @extend .list-reset;
    .social__vertical__el {
      a {
        display: block;
        width: 100%;
        svg {
          display: block;
          margin: 0 auto;
          color: $orange;
          width: 12px;
          transition: all .3s ease-in-out;
        }
        &:hover svg {
          color: $gray;
        }
      }
    }
  }
}


// Navigation Desktop
.main__navigation--mobile {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  background-color: $snow;
  transition: $slow;
  transform: translateY(-100%);
  .nav {
    @extend .list-reset;
    @extend .centerxy;
    .nav__el {
      width: 100%;
      height: auto;  
      margin: 20px 0px;
      a {
        @extend .btn__rect;
        font-size: 16px;
        padding: 10px 30px;
      }
    }
  }
  @media (min-width: $lg){
    display: none;
  }
  .social__horizontal {
    display: block;
    width: 160px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @extend .centerx;
    bottom: 5%;
    float: right;
    @extend .list-reset;
    @media (min-width: $lg){
      display: none;
    }
  }
}

.nav--mobile--open {
  transform: translateY(0%);
}