// HEADER Home
$header-home-left-max-width: 950px;
$header-home-left-top-position: 5%;
$header-home-right-max-width: 950px;
$header-home-right-top-position: 5%;
// HEADER STUDIO
$header-studio-max-width: 800px;
$header-studio-top-position: 15%;
// HEADER Blog
$header-blog-max-width: 900px;
$header-blog-top-position: 10%;
// HEADER Contact
$header-contact-max-width: 950px;
$header-contact-top-position: 10%;
// HEADER Article
$header-article-height: 70vh;
$padding-article: 250px 0px 0px;
// ------------------------------------------ //
.header__home {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column-reverse;
  @extend .block__left;
  @media(min-width: $md) {
    height: 100vh;
    display: block;
  }
  .container__img__left {
    margin-top: 40px;
    @media (min-width: $lg) {
      position: absolute;
      bottom: 80px;
      margin-top: 0px;
      margin-left: -150px;
    }
    @media (min-width: $xl) {
      margin-left: 0;
    }
  }
  .container__img__right {
    display: none;
    @media (min-width: $md) {
      display: block;
      position: absolute;
      bottom: 0;
      margin-left: 200px;
    }
    @media (min-width: $lg) {
      display: block;
      position: absolute;
      bottom: 150px;
      margin-left: 0;
    }
    @media (min-width: $xl) {
      margin-left: 0;
    }
  }
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    .content {
      span {
        display: block;
        @extend h3;
        margin: 10px 0 25px;
        font-weight: 700;
      }
      p {
        max-width: 350px;
      }
    }
    @media (min-width: $md) {
      padding: 50px 0;
    }
    @media (min-width: $lg) {
      padding: 0;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      position: relative;
      .content {
        width: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .small__title {
          display: block;
          margin: 10px 0 50px;
        }
      }
    }
    @media (min-width: $lg) {
      width: 100%;
    }
  }
}

.header__studio {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__right;
  @media(min-width: $md) {
    overflow: hidden;
    height: 100vh;
  }
  @extend .block__right;
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    .content {
      span {
        display: block;
        width: 100%;
        margin: 30px 0 15px;
      }
    }
    @media (min-width: $md) {
      padding: 0;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      position: relative;
      .content {
        width: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 450px;
        h3 {
          display: block;
          margin: 10px 0 50px;
        }
      }
    }
    @media (min-width: $lg) {
      width: 100%;
    }
  }
  .container__img__right {
    position: relative;
    display: block;
    width: 90%;
    height: auto;
    margin: 40px auto 0;
    img {
      width: 100%;
      height: auto;
      max-width: 550px;
    }
    @media (min-width: $md) {
      display: block;
      position: absolute;
      top: $header-studio-top-position;
      margin-left: 200px;
    }
    @media (min-width: $lg) {
      display: block;
      img {
        max-width: $header-studio-max-width;
      }
    }
    @media (min-width: $xl) {}
  }
}

.header__contact {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__right__small;
  @media(min-width: $md) {
    overflow: hidden;
    height: 100vh;
  }
  @extend .block__right;
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    .content {
      .small__italic {
        display: block;
        margin: 30px 0 15px;
      }
      .paragraph {
        max-width: 350px;
        margin-top: 20px;
      }
      .link__contact {
        width: 280px;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 40px;
        .link__contact__el {
          width: 48%;
          height: auto;
          h3 {
            margin-bottom: 10px;
          }
          a {
            color: $gray;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    @media (min-width: $md) {
      padding: 0;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      position: relative;
      .content {
        width: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .small__title {
          display: block;
          margin: 10px 0 50px;
        }
      }
    }
    @media (min-width: $lg) {
      width: 100%;
    }
  }
  .container__img__right {
    position: relative;
    display: block;
    width: 90%;
    height: auto;
    margin: 40px auto 0;
    img {
      width: 100%;
      height: auto;
      max-width: 550px;
    }
    @media (min-width: $md) {
      display: block;
      position: absolute;
      top: $header-contact-top-position;
      margin-left: 200px;
    }
    @media (min-width: $lg) {
      display: block;
      img {
        max-width: $header-contact-max-width;
      }
    }
  }
}

.header__casestudies {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__left;
  @media(min-width: $md) {
    overflow: hidden;
    height: 100vh;
  }
  @extend .block__right;
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    .content {
      h1 {
        margin-bottom: 20px;
      }
      .heading__projet {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: top;
        ul {
          display: block;
          margin: 0;
          max-width: 250px;
          margin-right: 20px;
          @extend .list-reset;
          li {
            float: left;
            margin-right: 10px;
            display: inline-block;
            position: relative;
            @extend h3;
            text-transform: uppercase;
          }
          br {
            display: none;
          }
        }
        .date {
          font-weight: 700;
          margin-top: 0;
        }
      }
    }
    @media (min-width: $md) {
      padding: 0;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      position: relative;
      .content {
        width: 55%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 400px;
        .small__title {
          display: block;
          margin: 10px 0 50px;
        }
      }
    }
    @media (min-width: $lg) {
      width: 100%;
      .content {
        width: 50%;
      }
    }
  }
  .container__img__right {
    position: relative;
    display: block;
    width: 90%;
    height: auto;
    margin: 40px auto 0;
    img {
      width: 100%;
      height: auto;
      max-width: 550px;
    }
    @media (min-width: $md) {
      display: block;
      position: absolute;
      bottom: 0;
      margin-left: 200px;
    }
    @media (min-width: $lg) {
      display: block;
      position: absolute;
      bottom: 20px;
      img {
        max-width: 750px;
      }
    }
    @media (min-width: $xl) {}
  }
}

.header__404 {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__right__center;
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    @media (min-width: $md) {
      padding: 200px 0 0;
      width: 100%;
      height: auto;
      margin: 0 auto;
      position: relative;
    }
    @media (min-width: $lg) {
      width: 100%;
    }
  }
  .container__img__right {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    margin: 40px auto 0;
    z-index: 1;
    img {
      width: 100%;
      height: auto;
      max-width: 550px;
    }
    .redirect__area {
      width: 100%;
      height: auto;
      position: relative;
      margin: 50px 0 100px;
      .btn__rect {
        margin: 0 auto;
      }
    }
    @media (min-width: $md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 100px;
      height: 400px;
      img {
        width: 50%;
      }
      .redirect__area {
        width: 50%;
        margin: 0;
      }
    }
    @media (min-width: $lg) {
      padding-bottom: 200px;
      img {
        max-width: 750px;
      }
    }
    @media (min-width: $xl) {}
  }
}

.header__blog {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__left;
  @media(min-width: $md) {
    overflow: hidden;
    height: 100vh;
  }
  @extend .block__right;
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    .content {
      .p--cat {
        @extend h3;
        text-align: left;
        width: 100%;
        margin: 10px 0px;
        a {
          color: $graylight;
        }
      }
      p {
        max-width: 450px;
      }
    }
    @media (min-width: $md) {
      padding: 0;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      position: relative;
      .content {
        width: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media (min-width: $lg) {
      width: 100%;
    }
  }
  .container__img__right {
    position: relative;
    display: block;
    width: 90%;
    height: auto;
    margin: 40px auto 0;
    img {
      width: 100%;
      height: auto;
      max-width: 550px;
    }
    @media (min-width: $md) {
      display: block;
      position: absolute;
      top: $header-blog-top-position;
      margin-left: 200px;
    }
    @media (min-width: $lg) {
      display: block;
      img {
        max-width: $header-blog-max-width;
      }
    }
  }
}

.header__article {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__left__bottom;
  padding: 150px 0px 0px;
  h1 {
    margin-bottom: 20px;
  }
  .header__picture {
    width: 100%;
    height: auto;
    overflow: hidden;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .container__header__intro {
    width: 100%;
    height: auto;
    div {
      .p--lg {
        font-size: 14px;
        margin: 20px 0px;
        max-width: 600px;
      }
    }
    p {
      margin: 0px;
    }
  }
  @media(min-width: $sm) {
    .header__picture {
      height: $header-article-height;
    }
    .container__header__intro {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
      div:first-child {
        padding: 40px 0px;
        .p--lg {
          margin: 0px;
        }
      }
    }
  }
  @media(min-width: $md) {
    height: calc(100vh - 150px);
  }
}