.section__blog {
    width: 100%;
    height: auto;
    padding: 50px 0px 150px;
}

.section__instagram {
    width: 100%;
    height: auto;
    padding: 0px;
    .insta__intro {
        margin-bottom: 50px;
        h3 {
            margin: 5px 0px;
        }
        p {
            @extend h3;
            color: $orange;
            font-size: 10.4px;
        }
        p,
        h3 {
            text-align: center;
        }
    }
}

.section__article__content {
    width: 100%;
    height: auto;
    padding: $padding-article;
    .author {
        margin: 100px auto 140px;
        width: 100%;
        max-width: 280px;
        display: flex;
        flex-direction: column;
        img {
            width: 75px;
            height: 75px;
            border-radius: 75px;
            margin: 0 auto;
        }
        h3 {
            padding: 30px 0px;
            text-align: center;
        }
        @media (min-width: $lg) {
            margin: 100px 0px 140px;
            flex-direction: row;
            justify-content: space-between;
            align-content: center;
            img {
                margin: 0;
            }
            h3 {
                text-align: left;
            }
        }
    }
}

.section__article__other {
    width: 100%;
    height: auto;
    padding: 0px 0px 100px;
    .other__subtitle {
        color: $gray;
        margin: 0px 0px 40px;
    }
    .cat {
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        @extend .list-reset;
        .cat-item {
            color: $orange;
            @extend h3;
            margin-bottom: 10px;
        }
        @media (min-width: $lg) {
            flex-direction: row;
            justify-content: space-between;
            .cat-item {
                margin: 0;
            }
        }
    }
}