/*@include border-box-sizing;*/

/*--------------------------------
  Breakpoints
---------------------------------*/
$xs: 0px;
$sm: 544px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

//Container width	None (auto)	576px	720px	940px	1140px

/*--------------------------------
  Layouts
---------------------------------*/
/*
$susy: (columns: 12, container: 100%, gutter: 60px/77px, column-width:77px, gutter-position: inside-static);
$small: (columns: 12, container: 576px, gutter: 60px/77px, column-width:77px, gutter-position: inside-static);
$medium: (columns: 12, container: 720px, gutter: 60px/77px, column-width:77px, gutter-position: inside-static);
$large: (columns: 12, container: 940px, gutter: 60px/77px, column-width:77px, gutter-position: inside-static);
$xlarge: (columns: 12, container: 1280px, gutter: 60px/77px, column-width: 77px, gutter-position: inside-static);
*/

/*--------------------------------
  Rules
---------------------------------*/
/*
.first, .second, .third, .fourth, .fifth, .sixth, .seventh, .eighth, .ninth, .tenth, .eleventh, .twelfth{
  @include span(1);
  background: blue;
  height: 200px;
  line-height: 200px;
  text-align: center;
}

.second, .fourth, .sixth, .eighth, .tenth, .twelfth{
  background: lighten(blue, 20%);
}

.container{
  @include container;
}

@include susy-breakpoint($sm, $small){
  .container{
    @include container;
  }
}

@include susy-breakpoint($md, $medium){
  .container{
    @include container;
  }
}

@include susy-breakpoint($lg, $large){
  .container{
    @include container;
  }
}

@include susy-breakpoint($xl, $xlarge){
  .container{
    @include container;
  }
}*/
