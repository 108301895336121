// Container Img
.container__img__right {
  width: 100%;
  height: auto;
  overflow: hidden;
  z-index: -1;
  img {
    display: block;
    width: auto;
    max-width: 950px;
    height: 100%;
    height: auto;
    float: right;
  }
}

.container__img__left {
  width: 90%;
  height: auto;
  margin: 0 auto;
  overflow: hidden;
  z-index: 2;
  img {
    display: block;
    width: auto;
    max-width: 950px;
    height: 100%;
    height: auto;
    float: left;
  }
}

// Container services
.container__services {
  width: 100%;
  height: auto;
  .services {
    display: flex;
    width: 100%;
    max-width: 700px;
    flex-direction: column;
    @extend .list-reset;
    @media (min-width: $md) {
      height: 300px;
      flex-direction: row;
      justify-content: center;
    }
    .services__el {
      margin-top: 50px;
      @media (min-width: $md) {
        margin-top: 0px;
        width: 33.3333%;
      }
      h3 {
        margin: 10px 0px 26px;
      }
      .skill {
        display: flex;
        flex-direction: column;
        @extend .list-reset;
        .skill__el {
          max-width: 440px;
          margin-bottom: .4em;
        }
      }
    }
  }
}

// Container projets
.container__projets {
  width: 100%;
  height: auto;
  .projets__list {
    width: inherit;
    display: inline-block;
    margin-top: 50px;
    padding-bottom: 100px;
    @extend .list-reset;
    .projets__list__el {
      display: inline-block;
      margin-bottom: 40px;
      @media (min-width: $md) {
        width: calc(50% - 44px);
        margin: 40px 20px 0px 0px;
      }
      @media (min-width: $lg) {
        width: calc(33% - 40px);
        margin: 40px 20px 0px 0px;
      }
      .projets__list__el__link {
        display: block;
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: auto;
          max-height: 452px;
          transition: $slow;
        }
        &:hover,
        &:focus,
        &:active {
          img {
            transform: scale(1.1);
          }
        }
      }
      .projet__content {
        width: 100%;
        height: auto;
        h3 {
          margin: 20px 0px 0px;
        }
        .projet__categories {
          display: block;
          height: auto;
          width: 90%;
          @extend .list-reset;
          .projet__categories__el {
            display: inline-block;
            margin-right: 10px;
            @extend p;
            position: relative;
            &:before {
              // content: '/';
              // display: block;
              // width: 10px;
              // height: auto;
              // position: absolute;
              // right: -15px;
            }
          }
          .projet__categories__el:last-child {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  }
}

.container__services__img {
  width: 100%;
  height: auto;
  .services__img__block {
    width: 100%;
    height: auto;
    @media (min-width: $md) {
      position: absolute;
      right: 0%;
      top: 0;
      max-width: 460px;
      z-index: -1;
    }
    @media (min-width: $xl) {
      right: 12%;
    }
    img {
      width: 100%;
      height: auto;
    }
    .link__block {
      width: 100%;
      height: auto;
      margin: 20px 0px 50px;
      .btn__rect {
        position: relative;
        z-index: 2;
        max-width: 250px;
        margin: 0px auto;
        @media (min-width: $md) {
          margin-bottom: 0px;
          float: right;
          min-width: 250px;
        }
      }
    }
  }
}

// Container members
.container__members {
  h2 {
    margin-bottom: 40px;
  }
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  height: auto;
  .members {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    @extend .list-reset;
    .members__el {
      width: inherit;
      .members__img {
        width: inherit;
        height: auto;
        img {
          width: 100%;
          height: auto;
        }
      }
      .members__content {
        width: 100%;
        max-width: 400px;
        margin: 20px 0px 40px;
        span {
          display: block;
          i {
            display: block;
            width: 100%;
            margin: 5px 0px 15px;
          }
        }
      }
      @media (min-width: $md) {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 200px;
        img {
          max-width: 400px;
        }
        .members__content,
        .members__img {
          width: 48%;
        }
      }
    }
    .members__el:nth-child(even) {
      @media (min-width: $md) {
        flex-direction: row-reverse;
        margin-bottom: 100px;
        img {
          max-width: 500px;
        }
        h3,
        i,
        p {
          text-align: right !important;
        }
      }
    }
  }
}

.container__article {
  width: 100%;
  .article {
    @extend .list-reset;
    .article__el {
      display: block;
      width: 100%;
      margin-bottom: 80px;
      h2 {
        margin: 5px auto;
      }
      p a {
        color: $graylight;
        transition: all .3s ease-in-out;
      }
      p a:hover,
      p a:focus,
      p a:active {
        color: $orange;
      }
      p {
        @extend h3;
        margin: 20px auto 0px;
        a {
          color: $graylight;
        }
      }
      .date--article {
        @extend p;
        text-transform: initial;
        margin: 10px auto 0px;
      }
      .img__square {
        width: 100%;
        height: auto;
        min-height: 100px;
        overflow: hidden;
        background-color: $snow;
        img {
          display: block;
          width: 100%;
          height: auto;
          transition: $slow;
        }
        &:hover,
        &:focus,
        &:active {
          img {
            transform: scale(1.1);
          }
        }
      }
    }
    @media (min-width: $md) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      .article__el {
        width: 48%;
      }
    }
    @media (min-width: $lg) {
      display: flex;
      flex-direction: row;
      .article__el {
        width: 26%;
      }
    }
    @media (min-width: 1920px) {
      .article__el {
        width: 31%;
      }
    }
  }
}