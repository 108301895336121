.link__round {
  display: block;
  width: 100px;
  height: 100px;
  position: relative;
  background-color: transparent;
  border: 1px solid $orange;
  border-radius: 100px;
  transition: all .3s ease-in;
  span {
    font-family: $montserrat;
    font-weight: 700;
    font-size: 0.585em;
    text-transform: uppercase;
    text-align: center;
    line-height: 1.7em;
    letter-spacing: 1px;
    color: $orange;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all .3s ease-in;
    z-index: 2;
  }
  &:hover,
  &:active,
  &:focus {
    @extend .link-reset;
    background-color: $orange;
    span {
      color: $white;
    }
  }
}

.btn__rect {
  position: relative;
  display: block;
  width: auto;
  max-width: 200px;
  font-size: 0.625em;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .15em;
  color: $orange;
  padding: 10px 10px;
  transition: color .3s ease-in-out;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: $orange;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform-origin: left top;
    transform: scaleX(.04);
    transition: all .3s ease-in-out;
  }
  &:hover,
  &:active,
  &:focus {
    @extend .link-reset;
    color: $white;
    &:before {
      transform: scaleX(1);
    }
  }
}

.btn__menu {
  position: relative;
  display: block;
  width: 100px;
  @extend h3;
  text-align: center;
  letter-spacing: 1px;
  padding: 10px 10px;
  transition: color .3s ease-in-out;
  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-color: $snow;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transform-origin: left top;
    transform: scaleX(0);
    transition: all .3s ease-in-out;
  }
  &:hover,
  &:active,
  &:focus {
    @extend .link-reset;
    &:before {
      transform: scaleX(1);
    }
  }
}

.btn__round {
  display: block;
  width: 34px;
  height: 34px;
  border: 2px solid $orange;
  border-radius: 100px;
  background-color: transparent;
  position: relative;
  svg {
    color: $orange;
    @extend .centerxy;
  }
}