//CENTER-X
.centerx {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

//CENTER-Y 
.centery {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

//CENTER-XY
.centerxy {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateY(-50%) translateX(-50%);
}