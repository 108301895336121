footer {
  width: 100%;
  height: auto;
  background-color: $orange;
  padding: 50px 0;
  z-index: 2;
  position: relative;
  .accalmie__contact,
  .accalmie__studio {
    @extend .list-reset;
  }
  .footer__content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (min-width: $md) {
      align-items: flex-start;
    }
    .footer__studio {
      @media (min-width: $md) {
        li {
          font-size: 10.4px;
          letter-spacing: .1em;
          font-family: $libre;
          line-height: 1.9em;
          max-width: 440px;
          color: $white;
        }
      }
    }
    .footer__contact {
      @media (min-width: $md) {
        li {
          font-size: 10.4px;
          letter-spacing: .1em;
          font-family: $libre;
          line-height: 1.9em;
          max-width: 440px;
          a {
            display: block;
            color: $white;
            &:hover {
              text-decoration: underline;
            }
            &:active {
              text-decoration: underline;
            }
            &:focus {
              text-decoration: underline;
            }
          }
        }
      }
    }
    h3 {
      color: rgba($white, .7);
      margin: 20px auto;
    }
    .footer__logo {
      display: none;
      @media (min-width: $md) {
        display: block;
        width: 15px;
        fill: $white;
        margin-top: 20px;
        margin-right: 10px;
      }
      @media (min-width: $lg) {
        margin-right: 20px;
      }
    }
    p {
      display: none;
      @media (min-width: $lg) {
        display: block;
        width: 25%;
        color: $white;
        margin-top: 20px;
      }
    }
    .footer__contact,
    .footer__studio,
    .link__round {
      display: none;
      @media (min-width: $md) {
        display: block;
      }
    }
    .link__round {
      border: 1px solid $white;
      span {
        color: $white;
      }
      &:hover {
        background-color: $white;
        span {
          color: $orange;
        }
      }
      &:active {
        background-color: white;
        span {
          color: $orange;
        }
      }
      &:focus {
        background-color: white;
        span {
          color: $orange;
        }
      }
    }
    .footer__contact,
    .footer__social,
    .footer__studio {
      @media(min-width: $md) {
        width: 25%;
      }
      @media (min-width: $lg) {
        width: 15%;
      }
    }
    .footer__social {
      display: block;
      width: 150px;
      margin: 0 auto;
      height: auto;
      @media (min-width: $md) {
        width: auto;
        margin: 0;
      }
      h3 {
        display: none;
        @media (min-width: $md) {
          display: block;
        }
      }
      .accalmie__social {
        display: block;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @extend .list-reset;
        @media (min-width: $md) {
          width: 100px;
        }
        .accalmie__social__el {
          width: 30%;
          a {
            display: block;
            width: 100%;
            height: 100%;
            color: $white;
            svg {
              display: block;
              width: 12px;
              margin: 0 auto;
              transition: all 0.3s ease-in-out;
              @media (min-width: $md) {
                margin: 0;
              }
              &:hover {
                opacity: 0.6;
              }
            }
          }
        }
      }
    }
  }
  .footer__copyright {
    width: 100%;
    height: auto;
    @media (min-width: $md) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;
    }
    .btn__rect {
      color: $white;
      margin: 20px auto;
      z-index: 2;
      max-width: 250px;
      &:before {
        content: "";
        display: block;
        background-color: $white;
        mix-blend-mode: difference;
        z-index: 1;
      }
      @media (min-width: $md) {
        display: none;
      }
      &:hover {
        color: $orange;
        &:before {
          transform: scaleX(1);
        }
      }
      &:focus {
        color: $orange;
        &:before {
          transform: scaleX(1);
        }
      }
      &:active {
        color: $orange;
        &:before {
          transform: scaleX(1);
        }
      }
    }
    small {
      display: block;
      width: 100%;
      @extend h3;
      color: rgba($white, .7);
      text-align: center;
      @media (min-width: $md) {
        text-align: left;
      }
      a {
        display: inline-block;
        text-align: center;
        color: rgba($white, .7);
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .logo__antony {
      display: none;
      @media (min-width: $md) {
        display: block;
        width: 14px;
        height: 14px;
        float: right;
        transition: all 0.3s ease-in-out;
        img {
          width: 100%;
          height: auto;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}