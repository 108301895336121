.container__form {
  form {
    max-width: 600px;
  }
}

input[type=submit] {
  background-color: $orange;
  border: transparent;
  padding: 12px 30px;
  width: 100%;
  max-width: 200px;
  @extend h3;
  color: $white;
  border-radius: 2px;
  outline: none;
  cursor: pointer;
  transition: $slow;
  &:hover,
  &:focus,
  &:active {
    background-color: $gray;
  }
}

textarea {
  height: 100px;
  display: block;
  width: 100%;
  border-radius: 5px;
}

input[type=email],
input[type=tel],
input[type=text],
textarea {
  display: block;
  border-radius: 5px;
  width: calc(100% - 32px);
  background-color: $snow;
  border: 2px solid transparent;
  padding: 10px 14px;
  @extend h3;
  color: $gray;
  outline: none;
  transition: $slow;
  &:focus {
    border: 2px solid $orange;
  }
}

input[type=email]::placeholder,
input[type=tel]::placeholder,
input[type=text]::placeholder,
textarea::placeholder {
  color: rgba($gray, .3);
}

.wpcf7-form-control-wrap select {
  display: block;
  width: 100%;
  height: 40px;
  outline: none;
  background-color: $snow;
  border: transparent;
  @extend h3;
  &:focus {
    border: 2px solid $orange;
  }
}

.wpcf7-mail-sent-ok {
  display: block;
  background-color: #56cc6a;
  border: 0 solid transparent !important;
  border-radius: 2px;
  padding: 20px 30px;
  color: $white;
  @extend p;
  font-family: $montserrat;
}

.wpcf7-validation-errors {
  display: block;
  width: calc(100% - 60px);
  margin: 0;
  background-color: #c24d4d;
  border: 0 solid transparent !important;
  border-radius: 5px;
  padding: 20px 30px;
  @extend p;
  color: $white;
  font-family: $montserrat;
}

.wpcf7-not-valid-tip {
  margin-top: 10px;
  color: #c24d4d;
}

div.wpcf7-response-output {
  margin: 0em 0em 0em !important;
  padding: 20px 30px !important;
  border: 2px solid transparent;
}