.section__contact {
  width: 100%;
  height: auto;
  padding: 100px 0px;
  .block__content {
    p {
      max-width: 350px;
      margin-top: 20px;
    }
  }
  .container__form {
    margin-top: 40px;
  }
}
