.section__accalmie {
  width: 100%;
  height: auto;
  padding: 100px 0px 0px;
  @media (min-width: $md){
    padding: 150px 0px;
  }
  .container__services {
    width: 100%;
    @media (min-width: $md) {
      position: relative;
      top: 80px;
      .services {
        margin-top: 80px;
      }
    }
  }
  .container__img__right {
    @media (min-width: $lg){
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .btn__rect {
    position: relative;
    z-index: 2;
    margin: 50px auto;
  }
  .container__services__img {
    margin-top: 40px;
  }
}

.section__members {
  width: 100%;
  height: auto;
  padding: 50px 0px 0px;
  @media (min-width: $md){
    padding: 150px 0px;
  }
  .btn__rect {
    position: relative;
    z-index: 2;
    margin: 50px auto;
  }
}
