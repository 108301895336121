/*==========================================================================
  Shame
  @see http://csswizardry.com/2013/04/shame-css/
  Thanks https://github.com/heroheman/shepherd/blob/master/sass/_shame.scss
  Because hacks happen.

  Be very specific about what each piece of code is doing, and
  how to better fix it later
  ==========================================================================*/

// Try:  $ git blame _shame.scss

// Rules:
// ---------------
//   1. If it’s a hack, it goes in _shame.scss.
//   2. Document all hacks fully:
//   3. What part of the codebase does it relate to?
//   4. Why was this needed?
//   5. How does this fix it?
//   6. How might you fix it properly, given more time?
//   7. Do not blame the developer; if they explained why they had to do it then their reasons are probably (hopefully) valid.
//   8. Try and clean _shame.scss up when you have some down time.

// Example:
// ---------------
// Nav specificity fix.

// Someone used an ID in the header code (`#header a{}`) which trumps the
// nav selectors (`.site-nav a{}`). Use !important to override it until I
// have time to refactor the header stuff.

// .site-nav a { color:#BADA55!important; }


// .btn{
//// POSITION - BOXMODEL
//
//   display: block;
//   height: 60px;
//   width: 60px;
//   position: relative;
//   bottom: 0;
//   top: 0;
//   right: 0;
//   left: 0;
//   float: ;
//   margin: 10px;
//   padding: 10px;
//   vertical-align: ;
//
//// BACKGROUND
//
//   background: red;
//   background-image: cover;
//
//// BORDER - BOXSHADOW
//
//   border: 1px solid red;
//   border-radius: 5px;
//   box-shadow:;
//
//// ANIMATION
//
//   transition: 1s all
//
//// TEXT - FONT
//
//   font-family: Arial;
//   font-size: 16px;
//   font-weight: bold;
//   color: red;
// }



// Social Bar Left
.social__bar__left {
  display: none;
  @media (min-width: $lg){
    display: block;
    width: 50px!important;}}

// Body
i,
em {
  font-family: $libre;
  font-size: 10.4px!important;
  font-weight: 400!important;
  color: $gray;
  letter-spacing: 0.03em!important;
  line-height: 1.74em;}

// page home

.header__home {
  @extend .block__left;
  	@media(min-width: $md) {
    	height: 90vh!important;
	   }
     .container__img__left {
  padding-bottom: 1em!important;}

@media (min-width: 1000px) {
    .container__img__left {bottom: 70px!important; left:-60px!important;}
}
@media (min-width: 1000px) {
    .container__img__right {
      bottom: 40px!important;
      right:-20px!important;
      img{
          max-width: 450px!important;
      }
    }
}
@media (min-width: 1500px) {
    .container__img__left {bottom: 290px!important;left: 0px!important;}
}
@media (min-width: 1500px) {
    .container__img__right {
      top: 20%!important;
      left: 0px!important;
      img{
        max-width: 950px!important;
      }
    }
}


   .block__content {
  		@media (min-width: $lg) {
      		.content {
        		width: 50%;
        		position: absolute;
        		top: 40%!important;
        	}
    	}		
   	}
}

.section__about {
  .container__img__right {
    @media (min-width: $lg) {
      top: 20%!important;
    }
  }
}



.header__contact{
	@media (min-width: 768px) {height: 93vh!important;
    .container__img__right {top:44%!important;}
	}
	@media (min-width: 1000px) {
    .container__img__right {top: 20%!important; width: 100%!important;}
    .content {top: 40%!important;}
	}
}
.header__casestudies{
	@media (min-width: 768px){
		.container__img__right{
			top: 30%!important;
		}
	}
}


.section__projets__list {
  @extend .block__right__center;
  @media (min-width: $md) {
    padding: 350px 0 100px!important;
  }
}
.section__article__other .cat .cat-item{
	// padding: 0 10px !important;
}
.section__contact {
  padding: 0px 0px 20px 0px!important;}

// blog

.section__article__content .wrapper .container__center{
  // p,i,em {font-size: 11.6px!important;}
  // blockquote{font-size: 16px!important;}
	.avatar{
		    width: 75px!important;
    		height: 75px!important;
    		border-radius: 75px!important;
	}
	img{
	width: 100%!important;
	height: auto!important;
	}
  .emoji{
    width: 10px!important;
  }
}
.section__article__content .author{max-width: 180px!important;}
// casestudies 
  // catégories
  .header__casestudies .block__content .content .heading__projet ul {max-width: 300px!important;}

  // header pour page catégorie
.header__categorie {
  position: relative;
  width: 100%;
  height: auto;
  @extend .block__left;
  @media(min-width: $md) {
    overflow: hidden;
    height: 80vh;
  }
  @extend .block__right;
  .block__content {
    padding: 150px 0 0;
    width: 100%;
    height: auto;
    z-index: 2;
    .content {
      .p--cat {
        @extend h3;
        text-align: left;
        width: 100%;
        margin: 10px 0px;
        a {
          color: $graylight;
        }
      }
      p {
        max-width: 450px;
      }
    }
    @media (min-width: $md) {
      padding: 0;
      width: 100%;
      height: 100vh;
      margin: 0 auto;
      position: relative;
      .content {
        width: 50%;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    @media (min-width: $lg) {
      width: 100%;
    }
  }
 .container__img__right {
    position: relative;
    display: block;
    width: 90%;
    height: auto;
    margin: 40px auto 0;
    img {
      width: 100%;
      height: auto;
      max-width: 550px;
    }
    @media (min-width: $md) {
      display: block;
      position: absolute;
      top: $header-blog-top-position;
      margin-left: 200px;
    }
    @media (min-width: $lg) {
      display: block;
      img {
        max-width: $header-blog-max-width;}
    }
  }
}

// form


input[type=email],
input[type=tel],
input[type=text],
textarea {
  border-radius: 0px!important;
  background-color: $cream!important;
}
.wpcf7-form-control-wrap select {
  background-color: $cream!important;
}
.checkbox-420{
	span .wpcf7-list-item{
		display: block!important;
		// border-radius: 50%!important;
		// content: ''!important;

		.wpcf7-list-item-label{
			font-family: $montserrat!important;
  			font-size: 11.4px!important;
  			font-weight: 700!important;
  			color: $graylight!important;
  			letter-spacing: 0.19em!important;
  			line-height: 20px!important;
  			text-transform: uppercase!important;
  		}
	}
}


