.section__projets__list {
  width: 100%;
  height: auto;
  padding: 100px 0 0;
  @extend .block__right__center;
  @media (min-width: $md) {
    padding: 200px 0 100px;
  }
  .btn__rect {
    margin: 50px auto;
  }
}

.section__casestudies {
  position: relative;
  z-index: 10;
  padding: 100px 0;
  .showcase {
    width: 100%;
    height: auto;
    @extend .list-reset;
    .showcase__el {
      width: 100%;
      height: auto;
      margin-bottom: 50px;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
  .projets__navigation {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .switch__link {
      .btn__round {
        margin-bottom: 10px;
      }
      .btn__round--left {
        float: left;
      }
      .btn__round--right {
        float: right;
      }
      a {
        text-align: center;
      }
      .btn__rect {
        @extend .centerx;
        min-width: 150px;
      }
      .paragraph {
        clear: both;
      }
      .paragraph--right {
        text-align: right;
      }
      .paragraph--left {
        text-align: left;
      }
      @media (min-width: $md) {
        .btn__round--left {
          float: none;
          margin: 0 auto;
        }
        .btn__round--right {
          float: none;
          margin: 0 auto;
        }
        .paragraph--right {
          text-align: center;
        }
        .paragraph--left {
          text-align: center;
        }
      }
    }
  }
}