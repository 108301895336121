//Colors
$orange: #f6967d;
$gray: #4c4c4c;
$graylight: #cecece;
$stone: #999999;
$snow: #efefef;
$cream: #f7f7f7;
$white: #ffffff;


//Fonts
$libre: 'Libre Baskerville', serif;
$montserrat: 'Montserrat', sans-serif;
