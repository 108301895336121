.section__cgu {
  width: 100%;
  height: auto;
  padding: 100px 0;
  @extend .block__left;
  @media (min-width: $md) {
    padding: 200px 0 100px;
  }

  .main__title {
    margin-bottom: 40px;
  }
}
