.scroll {
  display: none;
  position: fixed;
  left: 50%;
  bottom: 0;
  width: 28px;
  height: 28px;
  transform: translateX(-50%);
  transition: all 0.3s ease-in-out;
  animation: scroll 3s infinite;
  z-index: 9996;
}
@keyframes scroll {
  0% {
    transform: translate(-50%, 0px);
    opacity: 0;
  }

  50% {
    transform: translate(-50%, -20px);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, 0px);
    opacity: 0;
  }
}
