.section__about {
  width: 100%;
  height: auto;
  padding: 100px 0px 0px;
  clear: both;
  @media (min-width: $md) {
    height: 100vh;
  }
  .container__services {
    @media (min-width: $md) {
      position: relative;
      top: 80px;
    }
  }
  .container__img__right {
    @media (min-width: $lg) {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .btn__rect {
    position: relative;
    z-index: 2;
    margin: 50px auto;
  }
}

.section__projets {
  width: 100%;
  height: auto;
  padding: 100px 0px 70px;
  clear: both;
  @media (min-width: $lg) {
    padding: 200px 0px;
  }
  @extend .block__left;
  .swiper__projets {
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
    @media (min-width: $md) {
      display: flex;
      flex-direction: row;
    }
    @media (min-width: $lg) {
      max-width: 1200px;
    }
    .swiper-wrapper {
      width: 80%;
      .swiper-slide {
        width: 80%;
        overflow: hidden;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        @media (min-width: $md) {
          width: 100%;
        }
        @media (min-width: $lg) {
          flex-direction: row-reverse;
          width: 100%;
        }
        .link__projet {
          display: block;
          width: 100%;
          height: 100%;
          max-height: 535px;
          overflow: hidden;
          position: relative;
          opacity: 0;
          &:before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background-color: $orange;
            position: absolute;
            top: 0;
            left: 0;
            transform-origin: top left;
            transition: all 1.4s ease-in-out;
            z-index: 2;
          }
          img {
            display: block;
            width: 100%;
            height: auto;
            z-index: 1;
            position: relative;
            transform: translateX(100px);
            transition: all 1.4s ease-in-out;
          }
        }
        .content {
          display: block;
          position: relative;
          width: 90%;
          margin: 0 auto;
          height: auto;
          max-width: 1200px;
          @media (min-width: $lg) {
            max-width: 600px;
            width: 50%;
          }
          h3 {
            text-align: left;
            margin: 50px 0px 20px;
            transform: translateX(-100px);
            opacity: 0;
            transition: all .6s ease-in-out;
            transition-delay: .6s;
          }
          .categories {
            width: 100%;
            height: auto;
            transform: translateX(-100px);
            opacity: 0;
            transition: all .6s ease-in-out;
            transition-delay: .7s;
            @extend .list-reset;
            .categories__el {
              margin: 0;
              @extend p;
              text-align: left;
            }
          }
          .btn__rect {
            margin: 50px auto 0px;
            transform: translateX(-100px);
            opacity: 0;
            @media (min-width: $lg) {
              margin: 50px 0;
            }
          }
        }
      }
    }
    .arrow {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      z-index: 3;
      position: absolute;
      top: 0;
      @media (min-width: $lg) {
        width: 40px;
        flex-direction: column;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      .arrow__el {
        width: 20px;
        height: 20px;
        padding: 10px;
        background-color: $white;
        cursor: pointer;
        box-shadow: 10px 10px 40px 5px rgba(246, 150, 125, 0.15);
        position: relative;
        transition: all .3s ease-in-out;
        @media (min-width: $lg) {
          width: 100%;
          margin-bottom: 10px;
        }
        svg {
          color: $orange;
          @extend .centerxy;
          transition: all .3s ease-in-out;
        }
        &:hover {
          background-color: $orange;
          svg {
            color: $white;
          }
        }
      }
    }
  }
}