/*
Theme Name: Accalmie Studio
Author: Antony Paquay
Author URI: https://antonypaquay.be
Description: Code with passion ♥
Version: 1.0
*/

/* Tools */
@import "../node_modules/susy/sass/susy";
@import "../node_modules/breakpoint-sass/stylesheets/breakpoint";
@import "tools/grid";
@import "tools/mixins";
@import "tools/animate";

/* Site settings */
@import "base/normalize";
@import "base/reset";
@import "base/sitesettings";
@import "base/base";

/* Modules */
@import "modules/buttons";
@import "modules/slider";
@import "modules/scroll";
@import "modules/form";

/* Layout */
@import "layouts/header";
@import "layouts/footer";
@import "layouts/container";
@import "layouts/section__home";
@import "layouts/section__projets";
@import "layouts/section__blog";
@import "layouts/section__cgu";
@import "layouts/section__studio";
@import "layouts/section__contact";
@import "layouts/nav";

/* Vendor */
@import "vendors/outdatedbrowser";

/* SHAME */
@import "shame";
