//BASE
body {
  padding: 0;
  background-color: $white;
  color: $gray;
  font-family: $montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  z-index: 9999;
  .loader__block {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $orange;
    z-index: 2;
  }
}

// Disable Instagram Feed Elts
.sb_instagram_header,
#sbi_load {
  display: none;
}

// Transition 
$slow: all 0.65s cubic-bezier(.6, .01, .39, .99);
.list-reset {
  list-style: none;
  padding: 0;
  li {
    margin: 0;
  }
}

.link-reset {
  text-decoration: none;
}

.link__contact {
  @extend .list-reset;
}

section {
  position: relative;
  z-index: 1;
}

// Verticales lines
.verticle__lines {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  height: 100vh;
  max-width: 1200px;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
  .lines {
    width: 1px;
    height: 100%;
    background-color: $cream;
  }
}

// Container Center
.wrapper {
  display: block;
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: auto;
  max-width: 1200px;
}

.block__left {
  &:before {
    content: "";
    display: block;
    height: 85vh;
    width: 75%;
    background-color: $cream;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    @media (min-width: $lg) {
      width: 85%;
      height: 85vh;
    }
  }
}

.block__left__bottom {
  &:before {
    content: "";
    display: block;
    height: 85vh;
    width: 50%;
    background-color: $cream;
    position: absolute;
    bottom: -120px;
    left: 0;
    z-index: -1;
    @media (min-width: $lg) {
      width: 40%;
      height: 100vh;
    }
  }
}

.block__right {
  &:before {
    content: "";
    display: block;
    height: 85vh;
    width: 75%;
    background-color: $cream;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    @media (min-width: $lg) {
      width: 75%;
      height: 85vh;
    }
  }
}

.block__right__small {
  &:before {
    content: "";
    display: block;
    height: 55vh;
    width: 75%;
    background-color: $cream;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: -1;
    @media (min-width: $lg) {
      width: 35%;
      height: 55vh;
    }
  }
}

.block__right__center {
  &:before {
    content: "";
    display: block;
    height: 85vh;
    width: 75%;
    background-color: $cream;
    position: absolute;
    top: 50%;
    right: 0;
    z-index: -1;
    transform: translateY(-50%);
    @media (min-width: $lg) {
      width: 75%;
      height: 85vh;
    }
  }
}

.block__right__fixed {
  &:before {
    content: "";
    display: block;
    height: 85vh;
    width: 75%;
    background-color: $cream;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -1;
    @media (min-width: $lg) {
      width: 75%;
      height: 85vh;
    }
  }
}

.container__center {
  display: block;
  width: 100%;
  height: auto;
  max-width: 960px;
  margin: 0 auto;
}

section {
  position: relative;
}

// Style Fonts
h1 {
  font-family: $montserrat;
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.4em;
  letter-spacing: 2px;
  color: $orange;
  margin: 0;
  max-width: 500px;
  @media (min-width: $lg) {
    font-size: 2em;
    line-height: 50px;
    max-width: 650px;
  }
}

h2 {
  font-family: $montserrat;
  font-size: 16px;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1.6em;
  letter-spacing: 0.197em;
  color: $orange;
}

h3 {
  font-family: $montserrat;
  font-size: 11.4px;
  font-weight: 700;
  color: $graylight;
  letter-spacing: 0.19em;
  line-height: 20px;
  text-transform: uppercase;
}

p {
  margin: 20px 0;
  line-height: 2em;
  letter-spacing: 0.025em;
  font-size: 11.4px;
  font-family: $libre;
}

li {
  margin: 10px 0;
  line-height: 2em;
  letter-spacing: 0.025em;
  font-size: 10.4px;
  font-family: $libre;
}

li a svg {
  font-size: 14px !important;
}

span {
  a {
    transition: all .3s ease-in-out;
    &:hover,
    &:focus,
    &:active {
      color: $orange;
    }
  }
}

a {
  color: $orange;
  transition: all .3s ease-in-out;
  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

strong {
  font-weight: 700;
}

p>img {
  margin: 50px 0px;
}

i,
em {
  font-family: $libre;
  font-size: 10.4px;
  font-weight: 700;
  color: $gray;
  letter-spacing: 0.23em;
  line-height: 1.74em;
}

blockquote p {
  margin: 20px 0;
  line-height: 2.4em;
  text-align: center;
  letter-spacing: 0.025em;
  font-size: 1em;
  color: $orange;
  font-family: $libre;
}

.layoutArea img {
  display: block;
  width: 100%;
  height: auto;
}